import domReady from '@roots/sage/client/dom-ready';
import { initializeBlock } from '@scripts/initializeBlock';

const initializeLitepicker = async (block) => {
  const { default: Litepicker } = await import('litepicker');

  const datePickerContainer = block.querySelector('.date-picker-container');

  if (!datePickerContainer) return;

  const checkIn = datePickerContainer.querySelector('.check-in');
  const checkOut = datePickerContainer.querySelector('.check-out');
  const today = new Date();
  const twoDaysFromToday = new Date().setDate(new Date().getDate() + 2);
  const rangePicker = new Litepicker({
    element: checkIn,
    parentEl: datePickerContainer,
    elementEnd: checkOut,
    firstDay: 1,
    format: 'D MMM, YYYY',
    lang: 'en-US',
    numberOfMonths: 1,
    numberOfColumns: 1,
    minDate: today,
    maxDate: null,
    minDays: null,
    maxDays: null,
    selectForward: false,
    selectBackward: false,
    splitView: false,
    singleMode: false,
    autoApply: true,
    showWeekNumbers: false,
    showTooltip: true,
    disableWeekends: false,
    mobileFriendly: true,
    hotelMode: true,
    tooltipText: {
      one: 'night',
      other: 'nights',
    },
  });

  rangePicker.setDateRange(today, twoDaysFromToday);
};

/**
 * Dom ready
 */
domReady(async () => {
  initializeBlock('.wp-block-hero-background-date-picker', initializeLitepicker);
});
