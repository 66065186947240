/**
 * Initialize a block element with a specified function when it becomes available in the DOM.
 *
 * @param {string} blockSelector - The CSS selector of the block element to initialize.
 * @param {function} initializeFunction - The function to call to initialize the block element.
 */
export const initializeBlock = (blockSelector, initializeFunction) => {
  const initialize = () => {
    const block = document.querySelectorAll(blockSelector);

    if (block) {
      block.forEach((block) => {
        initializeFunction(block);
      });
    }
  };

  if (typeof wp !== 'undefined' && wp.data && wp.domReady) {
    return;
  } else {
    initialize();
  }
};
